<template>
  <section>
    amazon-return
  </section>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      data: {},
    }
  },
  created() {},
  methods: {
  },
}
</script>
